import BigNumber from 'bignumber.js'
// import boostPools from 'config/constants/boostPools'
import { getMasterchefContract, getMmfBarContract } from 'utils/contractHelpers'
import { ChainId } from '@crocswap/mmf-arb-sdk'

const mmfBoostContract = getMmfBarContract()
const craftsmanContract = getMasterchefContract()

const pid = process.env.REACT_APP_CHAIN_ID === ChainId.MAINNET.toString() ? 0 : 27

export const fetchMmfBoostPool = async () => {
  const [totalSupply, totalAllocPoint, info] = await Promise.all([
    mmfBoostContract.totalSupply(),
    craftsmanContract.totalAllocPoint(),
    craftsmanContract.poolInfo(pid),
    // ...boostPools.map((pool) => craftsmanContract.poolInfo(pool.pid)),
  ])
  // const [totalSupply, totalAllocPoint, info, ...boostPoolInfo] = await Promise.all([
  //   mmfBoostContract.totalSupply(),
  //   craftsmanContract.totalAllocPoint(),
  //   craftsmanContract.poolInfo(pid),
  //   ...boostPools.map((pool) => craftsmanContract.poolInfo(pool.pid)),
  // ])

  // const formattedPoolInfo = boostPoolInfo.map((pool, i) => ({
  //   poolId: i,
  //   stakes: new BigNumber(pool.totalStaked._hex).toJSON(),
  // }))

  // console.log({boostPoolInfo, formattedPoolInfo, boostPools})

  return [
    new BigNumber(totalSupply._hex).toJSON(),
    new BigNumber(info.allocPoint._hex).div(new BigNumber(totalAllocPoint._hex)).toJSON(),
    // ...formattedPoolInfo,
  ]
}
