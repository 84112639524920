/* eslint-disable no-debugger */
import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers'
import { SerializedFarm } from 'state/types'
import tokens from 'config/constants/tokens'
import { TokenPriceFromAPI } from 'state/pools/helpers'
// import { Underline } from 'react-feather'

const getFarmFromTokenSymbol = (
  farms: SerializedFarm[],
  tokenSymbol: string,
  preferredQuoteTokens?: string[],
): SerializedFarm => {
  const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)
  const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarm
}

const getFarmBaseTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  ethPriceUsdc: BigNumber,
): BigNumber => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  if (farm.quoteToken.symbol === tokens.usdc.symbol) {
    return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.quoteToken.symbol === tokens.weth.symbol) {
    return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  // We can only calculate profits without a quoteTokenFarm for BUSD/ETH farms
  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't BUSD or WETH, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - ETH, (pBTC - ETH)
  // from the ETH - pBTC price, we can calculate the PNT - BUSD price
  if (quoteTokenFarm.quoteToken.symbol === tokens.weth.symbol) {
    const quoteTokenInUsdc = ethPriceUsdc.times(quoteTokenFarm.tokenPriceVsQuote)
    return hasTokenPriceVsQuote && quoteTokenInUsdc
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInUsdc)
      : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === tokens.usdc.symbol) {
    const quoteTokenInUsdc = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInUsdc
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInUsdc)
      : BIG_ZERO
  }

  // Catch in case token does not have immediate or once-removed BUSD/WETH quoteToken
  return BIG_ZERO
}

const getFarmQuoteTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  ethPriceUsdc: BigNumber,
  mmfPriceUsdc: BigNumber,
  dsfPrice: BigNumber,
): BigNumber => {
  if (farm.quoteToken.symbol === 'USDC') {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'USDT') {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'DAI') {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'WETH') {
    return ethPriceUsdc
  }

  if (farm.quoteToken.symbol === 'stETH') {
    return ethPriceUsdc
  }

  if (farm.quoteToken.symbol === 'MMF') {
    return mmfPriceUsdc
  }

  if (farm.quoteToken.symbol === 'DSF') {
    return dsfPrice
  }

  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'WETH') {
    return quoteTokenFarm.tokenPriceVsQuote ? ethPriceUsdc.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'MMF') {
    return quoteTokenFarm.tokenPriceVsQuote ? mmfPriceUsdc.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'USDC') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'DAI') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'USDT') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'stETH') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  return BIG_ZERO
}

const fetchFarmsPrices = async (farms: SerializedFarm[]) => {
  const prices = await TokenPriceFromAPI(0, 0, 0)
  const ethusdcFarm = farms.find((farm) => farm.pid === 2) // FIXME pid for ETH/USDC
  const mmfusdcFarm = farms.find((farm) => farm.pid === 1) // FIXME

  // const ethusdcFarm = farms.find(
  //   (farm) => farm.token.symbol === tokens.usdc.symbol && farm.quoteToken.symbol === tokens.weth.symbol,
  // ) // FIXME pid for ETH/USDC

  // if (ethusdcFarm === undefined || mmfusdcFarm === undefined) {
  //   return farms
  // }

  const ethPriceUsdc = new BigNumber(ethusdcFarm.tokenPriceVsQuote)
  const mmfPriceUsdc = new BigNumber(mmfusdcFarm.tokenPriceVsQuote)

  // const mmfPriceUsdc = new BigNumber('0.2')
  // console.log(ethPriceUsdc.toString(), mmfPriceUsdc.toString())
  const dsfPrice = new BigNumber(prices['0x095bc617b36ab227a379550633dfdcbf43f236f6'] || '0')
  // const ethPriceUsdc = new BigNumber(0);
  // const mmfPriceUsdc = new BigNumber(0);
  // const svnPriceUsdc = new BigNumber(0);

  const farmsWithPrices = farms.map((farm) => {
    const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol)
    const tokenPriceBusd = getFarmBaseTokenPrice(farm, quoteTokenFarm, ethPriceUsdc)
    const quoteTokenPriceBusd = getFarmQuoteTokenPrice(farm, quoteTokenFarm, ethPriceUsdc, mmfPriceUsdc, dsfPrice)

    // if (farm.lpSymbol === 'DONUT-SVN LP') {
    //   return {
    //     ...farm,
    //     tokenPriceBusd: prices["0x2258459c937d0b4cb58289718c900b43c9c4813a"],
    //     quoteTokenPriceBusd: prices["0x654bac3ec77d6db497892478f854cf6e8245dca9"],
    //   }
    // }

    return {
      ...farm,
      tokenPriceBusd: tokenPriceBusd.toJSON(),
      quoteTokenPriceBusd: quoteTokenPriceBusd.toJSON(),
    }
  })

  return farmsWithPrices
}

export default fetchFarmsPrices
