import BigNumber from 'bignumber.js'
import { getMmfBoostAddress } from 'utils/addressHelpers'
import { getMasterchefContract, getMmfBarContract } from 'utils/contractHelpers'
import { serializeStake } from './helpers'

// MMFBar = veMMF
const mmfBarContract = getMmfBarContract()
const mmfBoostContract = getMasterchefContract()

export const fetchMmfBoostUser = async (account: string): Promise<[string, ReturnType<typeof serializeStake>]> => {
  try {
    const [userData, pendingMmf] = await Promise.all([
      mmfBoostContract.userInfo(0, account),
      mmfBoostContract.pendingMeerkat(0, account),
    ])
    const [, , stakes] = userData
    return [new BigNumber(pendingMmf._hex).toJSON(), stakes?.map(serializeStake)]
  } catch (e) {
    console.error(e)
  }
  return null
}

const fetchMmfBoostAllowance = async (account: string) => {
  try {
    const allowance = await mmfBarContract.allowance(account, getMmfBoostAddress())
    return new BigNumber(allowance._hex).toJSON()
  } catch (error) {
    return '0'
  }
}

export default fetchMmfBoostAllowance
