import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import {
  DeserializedBarData,
  DeserializedBoostData,
  DeserializedBoostPoolData,
  DeserializedMmfBoostStake,
  SerializedBarData,
  SerializedBoostData,
  SerializedBoostPoolData,
  SerializedMmfBoostStake,
} from '../types'

const transformStake = (stake: SerializedMmfBoostStake): DeserializedMmfBoostStake => {
  const { amount, weightedAmount, poolId, stakeTimestamp, unlockTimestamp, active } = stake
  return {
    amount: new BigNumber(amount),
    weightedAmount: new BigNumber(weightedAmount),
    poolId,
    stakeTimestamp,
    unlockTimestamp,
    active,
  }
}

export const transformBarData = (barData: SerializedBarData): DeserializedBarData => {
  const { allowance, stakedBalance, totalSupply } = barData

  return {
    allowance: new BigNumber(allowance),
    stakedBalance: new BigNumber(stakedBalance),
    totalSupply: new BigNumber(totalSupply),
    mine: BIG_ZERO,
    vaults: BIG_ZERO,
  }
}

export const transformBoostData = (boostData: SerializedBoostData): DeserializedBoostData => {
  const { allowance, pendingMmf, stakes } = boostData

  return {
    allowance: new BigNumber(allowance),
    pendingMmf: new BigNumber(pendingMmf),
    stakes: stakes?.map(transformStake) ?? [],
  }
}

export const serializeStake = (stake) => {
  const { amount, poolId, weightedAmount, stakeTimestamp, unlockTimestamp, active } = stake
  return {
    amount: new BigNumber(amount._hex).toJSON(),
    poolId: new BigNumber(poolId._hex).toNumber(),
    weightedAmount: new BigNumber(weightedAmount._hex).toJSON(),
    stakeTimestamp: new BigNumber(stakeTimestamp._hex).toNumber(),
    unlockTimestamp: new BigNumber(unlockTimestamp._hex).toNumber(),
    active,
  }
}

export const transformBoostPoolData = (boostPoolData: SerializedBoostPoolData): DeserializedBoostPoolData => {
  const { totalSupply, poolWeight, poolInfo } = boostPoolData
  return {
    totalSupply: new BigNumber(totalSupply),
    poolWeight: new BigNumber(poolWeight),
    poolInfo: poolInfo.map((pool) => ({ poolId: pool.poolId, stakes: new BigNumber(pool.stakes) })),
  }
}
