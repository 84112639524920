import React from 'react'
import styled from 'styled-components'
import { Card } from '@madmeerkatfinance/arbi-uikit'

export const BodyWrapper = styled(Card)`
  border-radius: 24px;
  max-width: 436px;
  width: 100%;
  z-index: 1;
  margin-top: 1em;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return (
    <BodyWrapper background="linear-gradient(to bottom, rgb(17, 17, 17), rgb(17, 24, 39), rgb(0, 0, 0))">
      {children}
    </BodyWrapper>
  )
}
