import BigNumber from 'bignumber.js'
import React from 'react'
import styled from 'styled-components'
import { BIG_ZERO } from 'utils/bigNumber'
import { Flex, Text, Box } from '@madmeerkatfinance/arbi-uikit'
import { useTranslation } from 'contexts/Localization'
import { PoolCategory } from 'config/constants/types'
import { DeserializedPool } from 'state/types'
import { UNLOCK_RATE } from 'config'
import { getBalanceNumber } from 'utils/formatBalance'
import { useApprovePool } from 'views/Pools/hooks/useApprove'
import { useERC20 } from 'hooks/useContract'
import ApprovalAction from './ApprovalAction'
import StakeActions from './StakeActions'
import HarvestActions from './HarvestActions'

const InlineText = styled(Text)`
  display: inline;
`

interface CardActionsProps {
  pool: DeserializedPool
  stakedBalance: BigNumber
}

const CardActions: React.FC<CardActionsProps> = ({ pool, stakedBalance }) => {
  const { sousId, stakingToken, earningToken, harvest, poolCategory, userData, earningTokenPrice } = pool
  // Pools using native ETH behave differently than pools using a token
  const isBnbPool = poolCategory === PoolCategory.BINANCE
  const { t } = useTranslation()
  const allowance = userData?.allowance ? new BigNumber(userData.allowance) : BIG_ZERO
  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO

  const rawEarnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO

  const earnings = rawEarnings.times(UNLOCK_RATE).div(10000)
  const earningTokenBalance = getBalanceNumber(earnings, earningToken.decimals)
  const earningTokenDollarBalance = getBalanceNumber(earnings.multipliedBy(earningTokenPrice), earningToken.decimals)
  // console.log(earningToken.symbol, earnings.toString(10), earningTokenBalance, earningTokenPrice, earningTokenDollarBalance)

  const xEarnings = rawEarnings.times(10000 - UNLOCK_RATE).div(10000)
  const xEarningTokenBalance = getBalanceNumber(xEarnings, earningToken.decimals)
  const xEarningTokenDollarBalance = getBalanceNumber(xEarnings.multipliedBy(earningTokenPrice), earningToken.decimals)

  const stakingTokenContract = useERC20(stakingToken.address || '')
  const { handleApprove, requestedApproval } = useApprovePool(stakingTokenContract, sousId, earningToken.symbol)
  const needsApproval = !allowance.gt(0) && !isBnbPool
  const isStaked = stakedBalance.gt(0)
  const isLoading = !userData

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        {harvest && (
          <>
            {/* <Flex>
              <Box display="inline">
                <InlineText color="secondary" textTransform="uppercase" bold fontSize="12px">
                  {`${earningToken.symbol} `}
                </InlineText>
                <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                  {t('Earned')}
                </InlineText>
              </Box>
              <Box display="inline">
                <InlineText color="secondary" textTransform="uppercase" bold fontSize="12px">
                  xMMF
                </InlineText>
                <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                  {t('Earned')}
                </InlineText>
              </Box>
            </Flex> */}
            <HarvestActions
              earnings={earnings}
              xEarnings={xEarnings}
              earningTokenBalance={earningTokenBalance}
              xEarningTokenBalance={xEarningTokenBalance}
              earningToken={earningToken}
              earningTokenDollarBalance={earningTokenDollarBalance}
              xEarningTokenDollarBalance={xEarningTokenDollarBalance}
              sousId={sousId}
              earningTokenPrice={earningTokenPrice}
              isBnbPool={isBnbPool}
              isLoading={isLoading}
            />
          </>
        )}
        <Box display="inline">
          <InlineText color={isStaked ? 'secondary' : 'textSubtle'} textTransform="uppercase" bold fontSize="12px">
            {isStaked ? stakingToken.symbol : t('Stake')}{' '}
          </InlineText>
          <InlineText color={isStaked ? 'textSubtle' : 'secondary'} textTransform="uppercase" bold fontSize="12px">
            {isStaked ? t('Staked') : `${stakingToken.symbol}`}
          </InlineText>
        </Box>
        {!isStaked && needsApproval ? (
          <ApprovalAction isLoading={isLoading} handleApprove={handleApprove} requestedApproval={requestedApproval} />
        ) : (
          <StakeActions
            isLoading={isLoading}
            pool={pool}
            stakingTokenBalance={stakingTokenBalance}
            stakedBalance={stakedBalance}
            isBnbPool={isBnbPool}
            isStaked={isStaked}
            allowance={allowance}
            handleApprove={handleApprove}
            requestedApproval={requestedApproval}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default CardActions
