import React from 'react'
import styled from 'styled-components'
import { Skeleton, Text, Flex, Box, useModal, useMatchBreakpoints } from '@madmeerkatfinance/arbi-uikit'
import { DeserializedPool } from 'state/types'
import BigNumber from 'bignumber.js'
import { PoolCategory } from 'config/constants/types'
import { BIG_ZERO } from 'utils/bigNumber'
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import Balance from 'components/Balance'
import { useTranslation } from 'contexts/Localization'
import { UNLOCK_RATE } from 'config'
import BaseCell, { CellContent } from './BaseCell'
import CollectModal from '../../PoolCard/Modals/CollectModal'

interface EarningsCellProps {
  pool: DeserializedPool
  account: string
  userDataLoaded: boolean
}

const StyledCell = styled(BaseCell)`
  flex: 4.5;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex: 1 0 120px;
  }
`

const EarningsCell: React.FC<EarningsCellProps> = ({ pool, account, userDataLoaded }) => {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const { sousId, earningToken, poolCategory, userData, earningTokenPrice } = pool
  const isManualCrocPool = sousId === 0

  const rawEarnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO

  const earnings = rawEarnings.times(UNLOCK_RATE).div(10000)
  const earningTokenBalance = getBalanceNumber(earnings, earningToken.decimals)
  const earningTokenDollarBalance = getBalanceNumber(earnings.multipliedBy(earningTokenPrice), earningToken.decimals)

  let xEarnings
  let xEarningTokenBalance
  let xEarningTokenDollarBalance
  let totalTokenDollarBalance = earningTokenDollarBalance

  if (isManualCrocPool) {
    xEarnings = rawEarnings.times(10000 - UNLOCK_RATE).div(10000)
    xEarningTokenBalance = getBalanceNumber(xEarnings, earningToken.decimals)
    xEarningTokenDollarBalance = getBalanceNumber(xEarnings.multipliedBy(earningTokenPrice), earningToken.decimals)
    totalTokenDollarBalance += xEarningTokenDollarBalance
  }

  const hasEarnings = account && earnings.gt(0)
  const fullBalance = getFullDisplayBalance(earnings, earningToken.decimals)
  const formattedBalance = formatNumber(earningTokenBalance, 3, 3)
  const isBnbPool = poolCategory === PoolCategory.BINANCE

  let labelText = t('%asset% Earned', { asset: earningToken.symbol })
  if (isManualCrocPool) {
    labelText = 'MMF + xMMF Earned'
  }

  const [onPresentCollect] = useModal(
    <CollectModal
      formattedBalance={formattedBalance}
      formattedxEarningsBalance={formatNumber(xEarningTokenBalance, 3, 3)}
      fullBalance={fullBalance}
      earningToken={earningToken}
      earningsDollarValue={earningTokenDollarBalance}
      xEarningsDollarValue={xEarningTokenDollarBalance}
      sousId={sousId}
      isBnbPool={isBnbPool}
      isCompoundPool={isManualCrocPool}
    />,
  )

  const handleEarningsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onPresentCollect()
  }

  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {labelText}
        </Text>
        {!userDataLoaded && account ? (
          <Skeleton width="80px" height="16px" />
        ) : (
          <>
            <Flex>
              <Box mr="8px" height="32px" onClick={hasEarnings ? handleEarningsClick : undefined}>
                <Flex alignItems="center">
                  <Balance
                    mt="4px"
                    bold={!isMobile}
                    fontSize={isMobile ? '14px' : '16px'}
                    color={hasEarnings ? 'primary' : 'textDisabled'}
                    decimals={hasEarnings ? 2 : 1}
                    value={hasEarnings ? earningTokenBalance : 0}
                  />
                  {isManualCrocPool && (
                    <>
                      <Text color={hasEarnings ? 'primary' : 'textDisabled'} paddingX="4px" paddingTop="3px">
                        +
                      </Text>
                      <Balance
                        mt="4px"
                        bold={!isMobile}
                        fontSize={isMobile ? '14px' : '16px'}
                        color={hasEarnings ? 'primary' : 'textDisabled'}
                        decimals={hasEarnings ? 2 : 1}
                        value={hasEarnings ? xEarningTokenBalance : 0}
                      />
                    </>
                  )}
                </Flex>
                {hasEarnings ? (
                  <>
                    {earningTokenPrice > 0 && (
                      <>
                        <Balance
                          display="inline"
                          fontSize="12px"
                          color="textSubtle"
                          decimals={2}
                          prefix="~"
                          value={totalTokenDollarBalance}
                          unit=" USD"
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Text mt="4px" fontSize="12px" color="textDisabled">
                    0 USD
                  </Text>
                )}
              </Box>
            </Flex>
          </>
        )}
      </CellContent>
    </StyledCell>
  )
}

export default EarningsCell
