import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GovernanceState, GovernanceUserBalance } from 'state/types'
import tokens from 'config/constants/tokens'
import { getTokenPricesFromFarm, TokenPriceFromAPI } from 'state/pools/helpers'
import { VEMMF_CLIENT } from 'config/constants/endpoints'
import { request, gql } from 'graphql-request'
import { VeMMFUserStatsResponse } from 'state/governance/queries/types'
import { fetchTokenGaugeAPR, fetchTokenGaugePast, fetchUserVoted, fetchUserVotedPastEpoch } from './actions'

const initialState: GovernanceState = {
  data: {
    isLoading: true,
    currentEpoch: 0,
    totalVEMMFHolder: 0,
    voterCount: 0,
    bribeInfo: {},
  },
  pastEpochData: {
    isLoading: true,
    currentEpoch: 0,
    totalVEMMFHolder: 0,
    voterCount: 0,
    bribeInfo: {},
  },
  pendingReward: {
    isLoading: true,
    pendingReward: {},
  },
  userBalance: {
    isLoading: true,
    balance: 0,
    tokenWeights: {},
  },
  userBalancePastEpoch: {
    isLoading: true,
    balance: 0,
    tokenWeights: {},
  },
  userDataLoading: false,
  isShowingPastEpoch: false,
  error: null,
}

// Thunks
export const fetchTokenGaugeAPRData = () => async (dispatch, getState) => {
  let totalVEMMFHolder = 0
  try {
    const query = gql`
      query queryUserStats {
        globalStats {
          id
          totalAddresses
          lockedAmount
        }
      }
    `
    const { globalStats } = await request<VeMMFUserStatsResponse>(VEMMF_CLIENT, query)
    totalVEMMFHolder = parseInt(globalStats?.[0]?.totalAddresses)
  } catch (e) {
    console.log(e)
  }
  const tokenGaugeAPR = await fetchTokenGaugeAPR()
  const tokenPriceMap = await TokenPriceFromAPI(0, 0, 0)
  const mmfPrice = tokenPriceMap[tokens.mmf.address.toLowerCase()]

  const farmData = getState().farms.data
  const prices = await TokenPriceFromAPI(0, 0, 0)
  const pricesFromFarm = getTokenPricesFromFarm(farmData)
  Object.keys(tokenGaugeAPR.bribeInfo).forEach((token) => {
    const { bribeTokenAddress } = tokenGaugeAPR.bribeInfo[token]
    const bribeAmountInUsd =
      tokenGaugeAPR.bribeInfo[token].bribeAmount *
      (pricesFromFarm[bribeTokenAddress.toLowerCase()] || prices[bribeTokenAddress.toLowerCase()] || 0)
    let yearlyAPR = 0
    if (mmfPrice) {
      const votingTVL = tokenGaugeAPR.bribeInfo[token].epochTokenBalance * mmfPrice
      const yearlyBribeUsd = (bribeAmountInUsd / 14) * 365
      yearlyAPR = (yearlyBribeUsd / votingTVL) * 100
    }

    tokenGaugeAPR.bribeInfo[token].tokenPrice =
      pricesFromFarm[token] || pricesFromFarm[token.toLowerCase()] || prices[token.toLowerCase()]
    tokenGaugeAPR.bribeInfo[token].bribeAmountInUsd = bribeAmountInUsd
    tokenGaugeAPR.bribeInfo[token].yearlyAPR = parseFloat(yearlyAPR?.toFixed(2))
  })
  tokenGaugeAPR.totalVEMMFHolder = totalVEMMFHolder

  dispatch(setTokenGaugeAPRData(tokenGaugeAPR))
}

// Thunks
export const fetchPastEpochData = (selectedEpoch: number) => async (dispatch, getState) => {
  const tokenGaugeAPR = await fetchTokenGaugePast(selectedEpoch)

  const farmData = getState().farms.data
  const pricesFromFarm = getTokenPricesFromFarm(farmData)
  Object.keys(tokenGaugeAPR.bribeInfo).forEach((token) => {
    const bribeAmountInUsd =
      tokenGaugeAPR.bribeInfo[token].bribeAmount *
        pricesFromFarm[tokenGaugeAPR.bribeInfo[token].bribeTokenAddress.toLowerCase()] || 0

    tokenGaugeAPR.bribeInfo[token].tokenPrice = pricesFromFarm[token]
    tokenGaugeAPR.bribeInfo[token].bribeAmountInUsd = bribeAmountInUsd
  })

  dispatch(setPastEpochData(tokenGaugeAPR))
}

export const fetchUserVotedData = createAsyncThunk<
  GovernanceUserBalance,
  { account: string; votingsContract; selectedEpoch?: number }
>('governance/fetchUserVotedData', async ({ account, votingsContract }, { dispatch }) => {
  dispatch(setFetchingFlag())
  return fetchUserVoted(account, votingsContract)
})

export const fetchUserVotedPasEpochData = createAsyncThunk<
  GovernanceUserBalance,
  { account: string; votingsContract; selectedEpoch?: number }
>('governance/fetchUserVotedPasEpochData', async ({ account, votingsContract, selectedEpoch }) => {
  return fetchUserVotedPastEpoch(account, votingsContract, selectedEpoch)
})

export const GovernanceSlice = createSlice({
  name: 'Governance',
  initialState,
  reducers: {
    setTokenGaugeAPRData: (state, action) => {
      state.data = action.payload
    },
    setPastEpochData: (state, action) => {
      state.pastEpochData = action.payload
    },
    setFetchingFlag: (state) => {
      state.userDataLoading = true
    },
    setShowingPastEpoch: (state, action) => {
      state.isShowingPastEpoch = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserVotedData.fulfilled, (state, action: PayloadAction<GovernanceUserBalance>) => {
      const data = action.payload
      data.isLoading = false
      state.userBalance = data
      state.userDataLoading = false
    })
    builder.addCase(fetchUserVotedData.rejected, (state, action) => {
      state.userDataLoading = false
      state.error = action.error
    })
    builder.addCase(fetchUserVotedPasEpochData.fulfilled, (state, action: PayloadAction<GovernanceUserBalance>) => {
      const data = action.payload
      state.userBalancePastEpoch = {
        isLoading: false,
        balance: data.balance,
        tokenWeights: data.tokenWeights,
      }
      state.pendingReward = {
        isLoading: false,
        pendingReward: data.resultPendingRewards,
      }
    })
  },
})

export const { setTokenGaugeAPRData, setPastEpochData, setFetchingFlag, setShowingPastEpoch } = GovernanceSlice.actions

export default GovernanceSlice.reducer
