import styled from 'styled-components'

export const ActionContainer = styled.div<{ isAutoVault?: boolean; isFlex?: boolean }>`
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 16px;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: ${({ isFlex }) => (isFlex ? 'flex' : 'block')};
    justify-content: ${({ isFlex }) => (isFlex ? 'space-between' : 'none')};
    align-items: center;
    margin-left: auto;
    margin-right: 12px;
    margin-bottom: 0;
    max-height: 100px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-left: 32px;
    margin-right: 0;
    margin-bottom: 0;
    max-height: 100px;
  }
`

export const ActionTitles = styled.div<{ isManualCrocPool?: boolean }>`
  font-weight: 600;
  font-size: 12px;
  display: ${({ isManualCrocPool }) => (isManualCrocPool ? 'flex' : 'block')};
  justify-content: ${({ isManualCrocPool }) => (isManualCrocPool ? 'space-between' : 'none')};
`

export const ActionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ActionBody = styled.div`
  flex-grow: 1;
  min-width: 220px;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 16px;
  }
`
