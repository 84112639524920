import { Token, ChainId } from '@crocswap/mmf-arb-sdk'
import tokens from './tokens'
import { Ifo } from './types'

// const mmfCroLPToken = new Token(ChainId.MAINNET, farms[1].lpAddresses[ChainId.MAINNET], 18, farms[1].lpSymbol)
// export const hknSvnLPToken = new Token(ChainId.MAINNET, '0x0fcffa1eD6b91B50dc80bB652f1111464A46338F', 18, 'HKN/SVN LP')
export const mmfUsdcLPToken = new Token(
  ChainId.MAINNET,
  '0x4119fE7EF21809629239ED2496211D61af78546e',
  18,
  'MMF-USDC LP',
)
export const mmfMmoLPToken = new Token(ChainId.MAINNET, '0xd6a88114DCC01f72D1B311aD6adF860724ff35d3', 18, 'MMF/MMO LP')
export const dsfMmfLPToken = new Token(ChainId.MAINNET, '0xfc48B66b9119f1d5fD7C8e72E7e489a5D6C0EF55', 18, 'DSF/MMF LP')

const ifos: Ifo[] = [
  {
    id: 'MMF',
    publicSalesAddress: '0x52392cFfC5E89C319da73370BD6aF1C64a440Da2',
    whitelistSaleAddress: '0x08382dd974322b9C292BBede5fef36dcEDa291F2',
    isActive: true,
    name: 'MM Finance (MMF)',
    poolWhitelist: {
      saleAmount: '2,500,000 MMF',
      raiseAmount: '$500,000 USD',
      cakeToBurn: '0% of MMF raised',
      distributionRatio: 0.5,
    },
    poolPublic: {
      saleAmount: '2,500,000 MMF',
      raiseAmount: '$500,000 USD',
      cakeToBurn: '0% of MMF raised',
      distributionRatio: 0.5,
    },
    description:
      'The beginning of a new era. MM.Finance is an entire ecosystem of DeFi apps and tools that is planned synergistically to build huge value. We are a community focused DEX with one of the lowest trading fees - 0.17% on the entire Polygon Network.',
    currency: tokens.usdc,
    // currency: mmfUsdcLPToken,
    token: tokens.mmf,
    startTimestamp: 1680350400,
    endTimestamp: 1680523200,
    // startTimestamp: 1680264000,
    // endTimestamp: 1680523200,
    campaignId: '1',
    articleUrl: 'https://medium.com/@MMFinance/74d80a5d469f',
    tokenOfferingPrice: 0.2,
    projectSite: 'https://arbimm.finance/',
    version: 1,
    loadIfoData: true,
    // vestingTitle: 'Use $MMF to enjoy high yields on MMF’s platform',
  },
]

export default ifos
