import React from 'react'
import { Button, AutoRenewIcon, Skeleton } from '@madmeerkatfinance/arbi-uikit'
import { useTranslation } from 'contexts/Localization'

interface ApprovalActionProps {
  isLoading?: boolean
  handleApprove: () => void
  requestedApproval: boolean
}

const ApprovalAction: React.FC<ApprovalActionProps> = ({ isLoading = false, handleApprove, requestedApproval }) => {
  const { t } = useTranslation()
  return (
    <>
      {isLoading ? (
        <Skeleton width="100%" height="52px" />
      ) : (
        <Button
          isLoading={requestedApproval}
          endIcon={requestedApproval ? <AutoRenewIcon spin color="currentColor" /> : null}
          disabled={requestedApproval}
          onClick={handleApprove}
          width="100%"
        >
          {t('Enable')}
        </Button>
      )}
    </>
  )
}

export default ApprovalAction
