import tokens from './tokens'

export const largeCapTokens = [
  tokens.eth.address,
  tokens.weth.address,
  tokens.usdc.address,
  tokens.wbtc.address,
  tokens.usdt.address,
  tokens.dai.address,
]
