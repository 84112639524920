import React from 'react'
import { Flex, Text, Button, Heading, useModal, Skeleton, Box } from '@madmeerkatfinance/arbi-uikit'
import BigNumber from 'bignumber.js'
import { Token } from '@crocswap/mmf-arb-sdk'
import { useTranslation } from 'contexts/Localization'
import { getFullDisplayBalance, formatNumber } from 'utils/formatBalance'
import Balance from 'components/Balance'
import styled from 'styled-components'
import CollectModal from '../Modals/CollectModal'

const InlineText = styled(Text)`
  display: inline;
`

interface HarvestActionsProps {
  earnings: BigNumber
  xEarnings: BigNumber
  earningTokenBalance: number
  xEarningTokenBalance: number
  earningTokenDollarBalance: number
  xEarningTokenDollarBalance: number
  earningToken: Token
  sousId: number
  earningTokenPrice: number
  isBnbPool: boolean
  isLoading?: boolean
}

const HarvestActions: React.FC<HarvestActionsProps> = ({
  earnings,
  earningToken,
  earningTokenBalance,
  xEarningTokenBalance,
  earningTokenDollarBalance,
  xEarningTokenDollarBalance,
  sousId,
  isBnbPool,
  earningTokenPrice,
  isLoading = false,
}) => {
  const { t } = useTranslation()

  const isManualCrocPool = sousId === 0
  // const earningTokenBalance = getBalanceNumber(earnings, earningToken.decimals)
  const formattedBalance = formatNumber(earningTokenBalance, 3, 3)

  // const earningTokenDollarBalance = getBalanceNumber(earnings.multipliedBy(earningTokenPrice), earningToken.decimals)

  const fullBalance = getFullDisplayBalance(earnings, earningToken.decimals)
  const hasEarnings = earnings.toNumber() > 0
  const isCompoundPool = sousId === 0

  const [onPresentCollect] = useModal(
    <CollectModal
      formattedBalance={formattedBalance}
      formattedxEarningsBalance={formatNumber(xEarningTokenBalance, 3, 3)}
      fullBalance={fullBalance}
      earningToken={earningToken}
      earningsDollarValue={earningTokenDollarBalance}
      xEarningsDollarValue={xEarningTokenDollarBalance}
      sousId={sousId}
      isBnbPool={isBnbPool}
      isCompoundPool={isCompoundPool}
    />,
  )

  return (
    <Flex justifyContent="space-between" alignItems="center" mb="16px">
      <Flex flexDirection="column">
        {isLoading ? (
          <Skeleton width="80px" height="48px" />
        ) : (
          <>
            <Box display="inline">
              <InlineText color="secondary" textTransform="uppercase" bold fontSize="12px">
                {`${earningToken.symbol} `}
              </InlineText>
              <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                {t('Earned')}
              </InlineText>
            </Box>
            {hasEarnings ? (
              <>
                <Balance bold fontSize="20px" decimals={5} value={earningTokenBalance} />
                {earningTokenPrice > 0 && (
                  <Balance
                    display="inline"
                    fontSize="12px"
                    color="textSubtle"
                    decimals={2}
                    prefix="~"
                    value={earningTokenDollarBalance}
                    unit=" USD"
                  />
                )}
              </>
            ) : (
              <>
                <Heading color="textDisabled">0</Heading>
                <Text fontSize="12px" color="textDisabled">
                  0 USD
                </Text>
              </>
            )}
          </>
        )}
      </Flex>
      {isManualCrocPool && (
        <Flex flexDirection="column">
          {isLoading ? (
            <Skeleton width="80px" height="48px" />
          ) : (
            <>
              <Box display="inline">
                <InlineText color="secondary" bold fontSize="12px">
                  xMMF{' '}
                </InlineText>
                <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                  {t('Earned')}
                </InlineText>
              </Box>
              {hasEarnings ? (
                <>
                  <Balance bold fontSize="20px" decimals={5} value={xEarningTokenBalance} />
                  {earningTokenPrice > 0 && (
                    <Balance
                      display="inline"
                      fontSize="12px"
                      color="textSubtle"
                      decimals={2}
                      prefix="~"
                      value={xEarningTokenDollarBalance}
                      unit=" USD"
                    />
                  )}
                </>
              ) : (
                <>
                  <Heading color="textDisabled">0</Heading>
                  <Text fontSize="12px" color="textDisabled">
                    0 USD
                  </Text>
                </>
              )}
            </>
          )}
        </Flex>
      )}
      <Button disabled={!hasEarnings} onClick={onPresentCollect}>
        {isCompoundPool ? t('Collect') : t('Harvest')}
      </Button>
    </Flex>
  )
}

export default HarvestActions
