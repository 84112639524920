import { createSlice } from '@reduxjs/toolkit'
import { AppThunk, VeMmfState } from 'state/types'
import fetchXMmfBarAllowance from 'state/xmmf/fetchXMmfBarUser'
import { fetchMmfBarBalance, fetchMmfBarTotalSupply } from './fetchMmfBarUser'
import { fetchMmfBoostPool } from './fetchMmfBoostPool'
import fetchMmfBoostAllowance, { fetchMmfBoostUser } from './fetchMmfBoostUser'

const initialState: VeMmfState = {
  userDataLoaded: false,
  barData: {
    allowance: '0',
    stakedBalance: '0',
    totalSupply: '0',
    vaults: '0',
    mine: '0',
  },
  boostData: {
    allowance: '0',
    pendingMmf: '0',
    stakes: [],
  },
  boostPoolData: {
    poolWeight: '0',
    totalSupply: '0',
    poolInfo: [],
  },
}

export const fetchMmfBarPoolDataAsync = (): AppThunk => async (dispatch) => {
  const stakedBalance = await fetchMmfBarBalance()
  const totalSupply = await fetchMmfBarTotalSupply()

  const userData = {
    stakedBalance,
    totalSupply,
  }

  dispatch(updateMmfBarUserData(userData))
}

export const fetchMmfBarUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const allowance = await fetchXMmfBarAllowance(account)

    const userData = {
      allowance,
    }

    dispatch(updateMmfBarUserData(userData))
  }

export const fetchMmfBoostUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const allowance = await fetchMmfBoostAllowance(account)
    const data = await fetchMmfBoostUser(account)

    const [pendingMmf, stakes] = data ?? ['0', []]

    const userData = {
      allowance,
      pendingMmf,
      stakes,
    }

    dispatch(setMmfBoostUserData(userData))
  }

export const fetchMmfBoostPoolDataAsync = (): AppThunk => async (dispatch) => {
  const data = await fetchMmfBoostPool()
  const [totalSupply, poolWeight, ...poolInfo] = data
  const poolData = {
    totalSupply,
    poolWeight,
    poolInfo,
  }
  dispatch(setMmfBoostPoolData(poolData))
}

export const veMmfSlice = createSlice({
  name: 'vemmf',
  initialState,
  reducers: {
    setMmfBarUserData: (state, action) => {
      state.barData = action.payload
      state.userDataLoaded = true
    },
    updateMmfBarUserData: (state, action) => {
      const value = action.payload
      state.barData = { ...state.barData, ...value }
      state.userDataLoaded = true
    },
    setMmfBoostUserData: (state, action) => {
      state.boostData = action.payload
      state.userDataLoaded = true
    },
    updateMmfBoostUserData: (state, action) => {
      const { field, value } = action.payload
      state.boostData = { ...state.boostData, [field]: value }
    },
    setMmfBoostPoolData: (state, action) => {
      state.boostPoolData = action.payload
    },
  },
})

// Actions
export const {
  updateMmfBarUserData,
  setMmfBarUserData,
  updateMmfBoostUserData,
  setMmfBoostUserData,
  setMmfBoostPoolData,
} = veMmfSlice.actions

export default veMmfSlice.reducer
