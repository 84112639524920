import { createSlice } from '@reduxjs/toolkit'
import { AppThunk, XMmfStateState } from 'state/types'
import fetchXMmfBarAllowance, {
  fetchXMmfBarBalance,
  fetchXMmfBarTotalSupply,
  fetchListUnlocking,
  fetchXMmfUnlockedBoost,
} from './fetchXMmfBarUser'

const initialState: XMmfStateState = {
  userDataLoaded: false,
  barData: {
    allowance: {
      xMmfAllowance: '0',
      xMmfUnlockerAllowance: '0',
    },
    stakedBalance: '0',
    totalSupply: '0',
    lockBoost: {},
    vaults: '0',
    mine: '0',
    listUnlocking: [],
  },
}

export const fetchXMmfBarPoolDataAsync = (): AppThunk => async (dispatch) => {
  const stakedBalance = await fetchXMmfBarBalance()
  const totalSupply = await fetchXMmfBarTotalSupply()
  const lockBoost = await fetchXMmfUnlockedBoost()

  const userData = {
    stakedBalance,
    totalSupply,
    lockBoost,
  }

  dispatch(updateXMmfBarUserData(userData))
}

export const fetchXMmfBarUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const allowance = await fetchXMmfBarAllowance(account)

    const userData = {
      allowance,
    }

    dispatch(updateXMmfBarUserData(userData))
  }

export const fetchListUnlockingDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const listUnlocking = await fetchListUnlocking(account)

    const userData = {
      listUnlocking,
    }

    dispatch(updateXMmfBarUserData(userData))
  }

export const xMmfSlice = createSlice({
  name: 'xmmf',
  initialState,
  reducers: {
    updateXMmfBarUserData: (state, action) => {
      const value = action.payload
      state.barData = { ...state.barData, ...value }
      state.userDataLoaded = true
    },
  },
})

// Actions
export const { updateXMmfBarUserData } = xMmfSlice.actions

export default xMmfSlice.reducer
