import React from 'react'
import styled from 'styled-components'
import { Card } from '@madmeerkatfinance/arbi-uikit'
import useLastTruthy from 'hooks/useLast'
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails'

const AdvancedDetailsFooter = styled(Card)<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin-top: 16px;
  width: 100%;
  max-width: 437px;
  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;
`

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }: AdvancedSwapDetailsProps) {
  const lastTrade = useLastTruthy(trade)

  return (
    <AdvancedDetailsFooter show={Boolean(trade)}>
      <div style={{ padding: '16px 0' }}>
        <AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
      </div>
    </AdvancedDetailsFooter>
  )
}
