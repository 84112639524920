import boostPools from 'config/constants/boostPools'
import tokens from 'config/constants/tokens'
import { BoostPoolInfo } from 'config/constants/types'
import { createContext, useReducer } from 'react'
import { tryParseAmount } from 'state/swap/hooks'

export interface xMmfContextType {
  selectLockPeriod: (pid: number) => void
  setStep: (n: number) => void
  goNext: () => void
  goPrev: () => void
  onChange: (s: string) => void
  onChangeEpoch: (s: string) => void
  onReset: () => void
  state: xMmfReducerState
  error?: string
}

export const initState: xMmfReducerState = {
  selectedPool: undefined,
  step: 0,
  value: '',
  daysLocked: '',
}

export interface xMmfReducerState {
  selectedPool: BoostPoolInfo
  step: number
  value: string
  daysLocked: string
}

const ON_SELECT_POOL = 'ON_SELECT_POOL'
const ON_SET_STEP = 'ON_SET_STEP'
const ON_PREV = 'ON_PREV'
const ON_NEXT = 'ON_NEXT'
const ON_CHANGE_XMMF = 'ON_CHANGE_XMMF'
const ON_RESET = 'ON_RESET'
const ON_CHANGE_DAY_LOCKED = 'ON_CHANGE_DAY_LOCKED'

interface OnSelectPoolAction {
  type: typeof ON_SELECT_POOL
  value: number
}

interface OnSetStepAction {
  type: typeof ON_SET_STEP
  value: number
}

interface OnPrevAction {
  type: typeof ON_PREV
}

interface OnNextAction {
  type: typeof ON_NEXT
}

interface OnChangeAction {
  type: typeof ON_CHANGE_XMMF
  value: string
}

interface OnChangeEpochAction {
  type: typeof ON_CHANGE_DAY_LOCKED
  value: string
}

interface OnResetAction {
  type: typeof ON_RESET
}

type xMmfReducerAction =
  | OnSelectPoolAction
  | OnSetStepAction
  | OnPrevAction
  | OnNextAction
  | OnChangeAction
  | OnResetAction
  | OnChangeEpochAction

function reducer(state: xMmfReducerState, action: xMmfReducerAction): xMmfReducerState {
  switch (action.type) {
    case ON_SELECT_POOL:
      return { ...state, selectedPool: boostPools.find((x) => x.pid === action.value) }
    case ON_SET_STEP:
      return { ...state, step: action.value }
    case ON_PREV:
      return { ...state, step: state.step - 1 }
    case ON_NEXT:
      return { ...state, step: state.step + 1 }
    case ON_CHANGE_XMMF:
      return { ...state, value: action.value }
    case ON_CHANGE_DAY_LOCKED:
      return { ...state, daysLocked: action.value }
    case ON_RESET:
      return initState
    default:
      return { ...state }
  }
}

export const useXMmfContext = (): xMmfContextType => {
  const [state, dispatch] = useReducer(reducer, initState)

  const handleSelectPool = (pid: number) => {
    dispatch({ type: ON_SELECT_POOL, value: pid })
  }

  const goNext = () => dispatch({ type: ON_NEXT })
  const goPrev = () => dispatch({ type: ON_PREV })
  const handleReset = () => dispatch({ type: ON_RESET })
  const handleChange = (value: string) => dispatch({ type: ON_CHANGE_XMMF, value })
  const handleChangeEpoch = (value: string) => dispatch({ type: ON_CHANGE_DAY_LOCKED, value })
  const handleSetStep = (value: number) => dispatch({ type: ON_SET_STEP, value })

  let error: string | undefined

  const parsedAmount = tryParseAmount(state.value, tokens.xmmf)
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    selectLockPeriod: handleSelectPool,
    setStep: handleSetStep,
    goNext,
    goPrev,
    onChange: handleChange,
    onChangeEpoch: handleChangeEpoch,
    onReset: handleReset,
    state,
    error,
  }
}

const xMmfContext = createContext<xMmfContextType>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectLockPeriod: (pid: number) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setStep: (n: number) => undefined,
  goNext: () => undefined,
  goPrev: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: (s: string) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeEpoch: (s: string) => undefined,
  onReset: () => undefined,
  state: initState,
})

export default xMmfContext
