import BigNumber from 'bignumber.js'
import { getMmfUnlockerAddress, getXMmfBarAddress } from 'utils/addressHelpers'
import { getXMmfBarContract, getCrocContract, getMmfUnlockerContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import mmfUnlockerAbi from 'config/abi/mmfUnlocker.json'
import { BIG_TEN } from 'utils/bigNumber'

const croContract = getCrocContract()
const xmmfBarContract = getXMmfBarContract()
const xmmfUnlockerContract = getMmfUnlockerContract()

export const fetchXMmfBarBalance = async () => {
  const balance = await croContract.balanceOf(getXMmfBarAddress())
  return new BigNumber(balance._hex).toJSON()
}

export const fetchXMmfBarTotalSupply = async () => {
  const totalSupply = await xmmfBarContract.totalSupply()
  return new BigNumber(totalSupply._hex).toJSON()
}

export const fetchXMmfUnlockedBoost = async () => {
  const lockTimes = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
  const calls = lockTimes.map((x) => ({
    address: getMmfUnlockerAddress(),
    name: 'getLockBoost',
    params: [x * 86400],
  }))
  const lockBoost = await multicall(mmfUnlockerAbi, calls)
  return lockBoost
    .map((data, key) => ({
      [lockTimes[key] * 86400]: data.toString(),
    }))
    .reduce((obj, item) => ({ ...obj, ...item }), {})
}

const fetchXMmfBarAllowance = async (account: string) => {
  try {
    const allowance = await croContract.allowance(account, xmmfUnlockerContract.address)
    const allowanceXmmfUnlocker = await xmmfBarContract.allowance(account, xmmfUnlockerContract.address)

    return {
      xMmfAllowance: allowance.toString(),
      xMmfUnlockerAllowance: allowanceXmmfUnlocker.toString(),
    }
  } catch (error) {
    return {
      xMmfAllowance: '0',
      xMmfUnlockerAllowance: '0',
    }
  }
}

export const fetchListUnlocking = async (account: string) => {
  try {
    const numberUnlockRequest = await xmmfUnlockerContract.getDepositId(account)
    const numberUnlock = new BigNumber(numberUnlockRequest._hex).toNumber()
    if (numberUnlock > 0) {
      const calls = [...Array(numberUnlock).keys()].map((x) => ({
        address: getMmfUnlockerAddress(),
        name: 'getUserInfo',
        params: [account, x + 1],
      }))
      const userInfos = await multicall(mmfUnlockerAbi, calls)
      return userInfos.map((userInfo, index) => {
        const depositedAmount = userInfo[0]
        const depositedAt = userInfo[1]
        const lockTime = userInfo[2]
        const hasClaimed = userInfo[3]

        return {
          depositId: index + 1, // the ID of the unlock request
          depositedAmount: new BigNumber(depositedAmount._hex).div(BIG_TEN.pow(18)).toString(),
          depositedAt: depositedAt.toString(),
          lockTime: lockTime.toNumber(),
          hasClaimed,
        }
      })
    }
    return []
  } catch (error) {
    console.error(error)
    return []
  }
}

export default fetchXMmfBarAllowance
