import BigNumber from 'bignumber.js'
import tokens from 'config/constants/tokens'
import { getMmfBarAddress } from 'utils/addressHelpers'
import { getMmfBarContract, getCrocContract } from 'utils/contractHelpers'

const croContract = getCrocContract()
const mmfBarContract = getMmfBarContract()

export const fetchMmfBarBalance = async () => {
  const balance = await croContract.balanceOf(getMmfBarAddress())
  return new BigNumber(balance._hex).toJSON()
}

export const fetchMmfBarTotalSupply = async () => {
  const totalSupply = await mmfBarContract.totalSupply()
  return new BigNumber(totalSupply._hex).toJSON()
}

const fetchMmfBarAllowance = async (account: string) => {
  try {
    const allowance = await mmfBarContract.allowance(account, tokens.vemmf.address)
    return new BigNumber(allowance._hex).toJSON()
  } catch (error) {
    return '0'
  }
}

export default fetchMmfBarAllowance
