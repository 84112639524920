import { ChainId } from '@crocswap/mmf-arb-sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMasterChefAddress = () => {
  // if (window?.location?.pathname === '/farmsV2') {
  //   return getAddress(addresses.masterChef)
  // }
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}

export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCrocVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCrocVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCrocVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getPancakeSquadAddress = () => {
  return getAddress(addresses.pancakeSquad)
}
export const getCrocReferralAddress = () => {
  return getAddress(addresses.meerkatReferral)
}
export const getNFTSeries1Address = () => {
  return getAddress(addresses.nftSeries1)
}
export const getNullAddress = () => {
  return '0x0000000000000000000000000000000000000000'
}
export const getDualChefAddress = () => {
  return getAddress(addresses.dualChef)
}

export const getMmfBarAddress = () => {
  return getAddress(addresses.mmfBar)
}
export const getMmfBoostAddress = () => {
  return getAddress(addresses.mmfBoost)
}
export const getMmfUnlockerAddress = () => {
  return getAddress(addresses.mmfUnlocker)
}

export const getXMmfBarAddress = () => {
  return getAddress(addresses.xmmfBar)
}

export const getVotingsAddress = () => {
  return getAddress(addresses.votings)
}
