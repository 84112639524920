import { MenuItemsType, DropdownMenuItemType } from '@madmeerkatfinance/arbi-uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: 'Swap',
    href: 'https://arbimm.finance/swap',
    hideSubNav: true,
    external: true,
    items: [
      {
        label: t('Exchange'),
        href: 'https://arbimm.finance/swap',
        type: DropdownMenuItemType.EXTERNAL_LINK_SAME_TAB,
      },
      {
        label: t('Liquidity'),
        href: 'https://arbimm.finance/liquidity',
        type: DropdownMenuItemType.EXTERNAL_LINK_SAME_TAB,
      },
      // {
      //   label: t('Limit Order'),
      //   href: '/limitorder',
      // },
      // {
      //   label: t('Stable Swap'),
      //   href: 'https://stable.arbimm.finance/',
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      // },
      // {
      //   label: t('Bridge'),
      //   href: 'https://bridge.mm.finance/#/',
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      // },
      // {
      //   label: t('Leverage Trading'),
      //   href: 'https://madmex.io',
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      // },
    ],
  },
  {
    label: t('Farms'),
    href: 'https://arbimm.finance/farms',
    icon: 'Farm',
    showItemsOnMobile: false,
    items: [],
    external: true,
  },
  {
    label: t('Pools'),
    href: 'https://arbimm.finance/pools',
    icon: 'Earn',
    showItemsOnMobile: false,
    items: [],
    external: true,
  },
  {
    label: t('Governance'),
    href: 'https://arbimm.finance/xmmf',
    icon: 'Currency',
    hideSubNav: true,
    external: true,
    items: [
      {
        label: t('xMMF'),
        href: 'https://arbimm.finance/xmmf',
        type: DropdownMenuItemType.EXTERNAL_LINK_SAME_TAB,
      },
      {
        label: t('veMMF'),
        href: 'https://arbimm.finance/vemmf',
        type: DropdownMenuItemType.EXTERNAL_LINK_SAME_TAB,
      },
      {
        label: t('Governance'),
        href: 'https://arbimm.finance/governance',
        type: DropdownMenuItemType.EXTERNAL_LINK_SAME_TAB,
      },
    ],
  },
  // {
  //   label: 'Analytics',
  //   href: 'https://arbimm.finance/info',
  //   icon: 'Chart',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  // {
  //   label: t('Launchpad'),
  //   icon: 'Moon',
  //   href: 'https://arbimm.finance/launchpad',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  // {
  //   label: t('Earn'),
  //   href: '/farms',
  //   icon: 'Earn',
  //   hideSubNav: true,
  //   items: [
  //     {
  //       label: t('Farms'),
  //       href: '/farms',
  //     },
  //     {
  //       label: 'Pools',
  //       href: '/pools',
  //     },
  //     // {
  //     //   label: 'Vaults',
  //     //   href: 'https://vaults.arbimm.finance/vault',
  //     //   type: DropdownMenuItemType.EXTERNAL_LINK,
  //     // },
  //   ],
  // },
  // {
  // label: 'Defi',
  // href: '/dawnstar-external',
  // icon: 'LaurelLeft',
  // // hideSubNav: true,
  // items: [
  // {
  //   label: 'METF',
  //   href: 'https://metf.finance',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'DawnStar',
  //   href: 'https://dawnstar.finance',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'MadMex',
  //   href: 'https://madmex.io',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'MMF Money (MUSD)',
  //   href: 'https://mmf.money',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Hakuna Matata',
  //   href: 'https://hkn.finance',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  //   ],
  // },

  // {
  //   label: 'Vault',
  //   href: '/vault',
  //   showItemsOnMobile: false,
  //   icon: 'Currency',
  //   items: [],
  // },
  // {
  //   label: 'Launch',
  //   href: '/launchpad',
  //   icon: 'Prize',
  //   showItemsOnMobile: false,
  //   items: [
  // {
  //   label: 'Launchpad',
  //   href: '/launchpad',
  // },
  // {
  //   label: 'Madbox',
  //   href: '/madbox',
  // },
  //   ],
  // },
  // {
  //   label: t('Referrals'),
  //   href: '/referrals',
  //   icon: 'Community',
  //   showItemsOnMobile: false,
  //   items: [
  // {
  //   label: 'Referrals',
  //   href: '/referrals',
  // },
  // {
  //   label: 'Madbox',
  //   href: '/madbox',
  // },
  //   ],
  // },
  // {
  //   label: 'Analytics',
  //   href: '/analytics',
  //   icon: 'Chart',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  // {
  //   label: 'Defi',
  //   href: '/metf-external',
  //   icon: 'LaurelLeft',
  //   // hideSubNav: true,
  //   items: [
  //     {
  //       label: 'METF',
  //       href: 'https://metf.finance',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: 'Savanna',
  //       href: 'https://svn.finance',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: 'MMF Money (MUSD)',
  //       href: 'https://mmf.money',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: 'Hakuna Matata',
  //       href: 'https://hkn.finance',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //   ],
  // },

  // {
  //   label: t('Games'),
  //   href: '/croissant-external',
  //   icon: 'Predictions',
  //   showItemsOnMobile: false,
  //   hideSubNav: true,
  //   items: [
  // {
  //   label: 'Coin Toss',
  //   href: 'https://poly.croissant.games/coin-toss/WETH',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Muffin Roll',
  //   href: 'https://poly.croissant.games/muffin-roll/WETH',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Dice Roll',
  //   href: 'https://poly.croissant.games/dice-roll/WETH',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Roulette',
  //   href: 'https://croissant.games/roulette/MMF',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Cro Roll',
  //   href: 'https://croissant.games/cro-roll/MMF',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Sports Prediction',
  //   href: 'https://www.polygonports.io',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  //   ],
  // },

  // {
  //   label: t('Docs'),
  //   href: 'https://croc-swap.gitbook.io/crocswap/',
  //   icon: 'Docs',
  //   hideSubNav: true,
  //   items: [],
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: t('Win'),
  //   href: '/prediction',
  //   icon: 'Trophy',
  //   items: [
  //     {
  //       label: t('Prediction (BETA)'),
  //       href: '/prediction',
  //     },
  //     {
  //       label: t('Lottery'),
  //       href: '/lottery',
  //     },
  //   ],
  // },
  // {
  //   label: t('NFT'),
  //   href: `${nftsBaseUrl}`,
  //   icon: 'Nft',
  //   items: [
  //     {
  //       label: t('Overview'),
  //       href: `${nftsBaseUrl}`,
  //     },
  //     {
  //       label: t('Collections'),
  //       href: `${nftsBaseUrl}/collections`,
  //     },
  //   ],
  // },
  // {
  //   label: '',
  //   href: '#',
  //   icon: 'HamburgerClose',
  //   hideSubNav: true,
  //   items: [
  //     {
  //       label: 'Analytics',
  //       href: '/analytics',
  //     },
  //     {
  //       label: 'MMF Dex (Cronos)',
  //       href: 'https://cronosmm.finance',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  // {
  //   label: 'Analytics',
  //   href: '/analytics',
  //   icon: 'Chart',
  // },
  // {
  //   label: t('Referrals'),
  //   href: '/referrals',
  //   icon: 'Community',
  // },
  // {
  //   label: 'Mad Meerkat NFT',
  //   href: 'https://madmeerkat.io',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'MM Treehouse NFT',
  //   href: 'https://mmtreehouse.io',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Leveraged Yield Farm (Single)',
  //   href: 'https://app.singlefinance.io/single-click',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Lend (Mimas)',
  //   href: 'https://app.mimas.finance/markets',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Lend (Annex)',
  //   href: 'https://polygonapp.annex.finance/dashboard',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: t('Docs'),
  //   href: 'https://mmfinance.gitbook.io/docs/',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Dexscreener',
  //   href: 'https://dexscreener.com/arbitrum/mmfinance',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'CoinGecko',
  //   href: 'https://www.coingecko.com/en/exchanges/mm_finance',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Apply to list',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSf1bV9GhbjZ8dn9qKDFgEBVvchozK7_QDAXRfu0IxGTj3eedA/viewform',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Dex Screener',
  //   href: 'https://dexscreener.com/arbitrum/0xd15eb8710e28c23993968e671807d572189cc86e',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'CoinMarketCap',
  //   href: 'https://coinmarketcap.com/currencies/mm-finance-polygon/',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'CoinGecko',
  //   href: 'https://www.coingecko.com/en/coins/mmfinance-polygon',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: 'Matic Faucet',
  //   href: 'https://faucet.arbimm.finance',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // {
  //   label: t('Voting'),
  //   href: '/voting',
  // },
  // {
  //   type: DropdownMenuItemType.DIVIDER,
  // },
  // {
  //   label: t('Leaderboard'),
  //   href: '/teams',
  // },
  // {
  //   type: DropdownMenuItemType.DIVIDER,
  // },
  // {
  //   label: t('Blog'),
  //   href: 'https://medium.com/pancakeswap',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  // },
  // ],
  // },
]

export const cakePriceMarkets = {
  href: '#',
  items: [
    {
      label: 'DexScreener',
      href: 'https://dexscreener.com/arbitrum/0x56b251d4b493ee3956E3f899D36b7290902D2326',
      type: DropdownMenuItemType.EXTERNAL_LINK,
    },
    {
      label: 'GeckoTerminal',
      href: 'https://www.geckoterminal.com/arbitrum/pools/0x4119fe7ef21809629239ed2496211d61af78546e',
      type: DropdownMenuItemType.EXTERNAL_LINK,
    },
    // {
    //   label: 'CoinMarketCap',
    //   href: 'https://coinmarketcap.com/exchanges/mm-finance/',
    //   type: DropdownMenuItemType.EXTERNAL_LINK,
    // },
  ],
}

export default config
