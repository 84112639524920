/* eslint-disable no-debugger */
import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR, MMF_PER_YEAR } from 'config'
import lpAprs from 'config/constants/lpAprs.json'
import { FarmWithStakedValue } from 'views/Farms/components/FarmCard/FarmCard'

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Croc price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  poolWeight: BigNumber,
  cakePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
  farm: FarmWithStakedValue,
): { cakeRewardsApr: number; lpRewardsApr: number; dualRewardsApr?: number } => {
  // console.log(poolWeight.toString(10), cakePriceUsd.toString(10), poolLiquidityUsd.toString(10), farmAddress)
  const yearlyMMFRewardAllocation = poolWeight ? poolWeight.times(MMF_PER_YEAR) : new BigNumber(NaN)
  const cakeRewardsApr = yearlyMMFRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100)
  let cakeRewardsAprAsNumber = null
  if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = cakeRewardsApr.toNumber()
  }
  const lpRewardsApr = lpAprs[farmAddress?.toLocaleLowerCase()] ?? 0

  let dualRewardsAprAsNumber = 0
  if (farm.dualMasterchef) {
    const yearlyDualRewardAllocation = new BigNumber(31536000).times(farm.rewardPerSecond.div(1e18))
    const dualRewardsApr = yearlyDualRewardAllocation.times(farm?.rewardPrice).div(poolLiquidityUsd).times(100)
    // console.info(
    //   farm.lpSymbol,
    //   yearlyDualRewardAllocation.toString(),
    //   farm.rewardPerSecond.div(1e18).toString(),
    //   dualRewardsApr,
    // )

    if (!dualRewardsApr.isNaN() && dualRewardsApr.isFinite()) {
      dualRewardsAprAsNumber = dualRewardsApr.toNumber()
    }
  }
  // console.log(farm.lpSymbol, lpRewardsApr, farm.rewardPerSecond.toString())

  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr, dualRewardsApr: dualRewardsAprAsNumber }
}

export default null
