import React from 'react'
import { Button, Text, useModal, Flex, Skeleton, Heading } from '@madmeerkatfinance/arbi-uikit'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { PoolCategory } from 'config/constants/types'
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import { BIG_ZERO } from 'utils/bigNumber'
import { DeserializedPool } from 'state/types'

import { UNLOCK_RATE } from 'config'
import { ActionContainer, ActionTitles, ActionContent, ActionBody } from './styles'
import CollectModal from '../../PoolCard/Modals/CollectModal'

interface HarvestActionProps extends DeserializedPool {
  userDataLoaded: boolean
}

const HarvestAction: React.FunctionComponent<HarvestActionProps> = ({
  sousId,
  poolCategory,
  earningToken,
  userData,
  userDataLoaded,
  earningTokenPrice,
}) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  const isManualCrocPool = sousId === 0

  const rawEarnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO

  const earnings = rawEarnings.times(UNLOCK_RATE).div(10000)
  const earningTokenBalance = getBalanceNumber(earnings, earningToken.decimals)
  const earningTokenDollarBalance = getBalanceNumber(earnings.multipliedBy(earningTokenPrice), earningToken.decimals)
  // console.log(earningToken.symbol, earnings.toString(10), earningTokenBalance, earningTokenPrice, earningTokenDollarBalance)

  const xEarnings = rawEarnings.times(10000 - UNLOCK_RATE).div(10000)
  const xEarningTokenBalance = getBalanceNumber(xEarnings, earningToken.decimals)
  const xEarningTokenDollarBalance = getBalanceNumber(xEarnings.multipliedBy(earningTokenPrice), earningToken.decimals)

  const hasEarnings = earnings.gt(0)
  const fullBalance = getFullDisplayBalance(earnings, earningToken.decimals)
  const formattedBalance = formatNumber(earningTokenBalance, 3, 3)
  const isCompoundPool = sousId === 0
  const isBnbPool = poolCategory === PoolCategory.BINANCE

  const [onPresentCollect] = useModal(
    <CollectModal
      formattedBalance={formattedBalance}
      formattedxEarningsBalance={formatNumber(xEarningTokenBalance, 3, 3)}
      fullBalance={fullBalance}
      earningToken={earningToken}
      earningsDollarValue={earningTokenDollarBalance}
      xEarningsDollarValue={xEarningTokenDollarBalance}
      sousId={sousId}
      isBnbPool={isBnbPool}
      isCompoundPool={isCompoundPool}
    />,
  )

  let actionTitle

  if (isManualCrocPool) {
    actionTitle = (
      <>
        <Flex>
          <Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="4px">
            MMF
          </Text>
          <Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
            {t('Earned')}
          </Text>
        </Flex>

        <Flex>
          <Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="4px">
            xMMF
          </Text>
          <Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
            {t('Earned')}
          </Text>
        </Flex>
      </>
    )
  } else {
    actionTitle = (
      <>
        <Text fontSize="12px" bold color="secondary" as="span" textTransform="uppercase">
          {earningToken.symbol}{' '}
        </Text>
        <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
          {t('Earned')}
        </Text>
      </>
    )
  }

  if (!account) {
    return (
      <ActionContainer isFlex>
        <ActionBody>
          <ActionTitles isManualCrocPool={isManualCrocPool}>{actionTitle}</ActionTitles>
          <ActionContent>
            <Heading>0</Heading>
          </ActionContent>
        </ActionBody>
        <Button disabled>{isCompoundPool ? t('Collect') : t('Harvest')}</Button>
      </ActionContainer>
    )
  }

  if (!userDataLoaded) {
    return (
      <ActionContainer isFlex>
        <ActionBody>
          <ActionTitles isManualCrocPool={isManualCrocPool}>{actionTitle}</ActionTitles>
          <ActionContent>
            <Skeleton width={180} height="32px" marginTop={14} />
          </ActionContent>
        </ActionBody>
      </ActionContainer>
    )
  }

  return (
    <ActionContainer isFlex>
      <ActionBody>
        <ActionTitles isManualCrocPool={isManualCrocPool}>{actionTitle}</ActionTitles>
        <ActionContent>
          <div>
            <Flex flex="1" flexDirection="column" alignSelf="flex-start">
              <>
                {hasEarnings ? (
                  <>
                    <Balance lineHeight="1" bold fontSize="20px" decimals={2} value={earningTokenBalance} />
                    {earningTokenPrice > 0 && (
                      <Balance
                        display="inline"
                        fontSize="12px"
                        color="textSubtle"
                        decimals={2}
                        prefix="~"
                        value={earningTokenDollarBalance}
                        unit=" USD"
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Heading color="textDisabled">0</Heading>
                    <Text fontSize="12px" color="textDisabled">
                      0 USD
                    </Text>
                  </>
                )}
              </>
            </Flex>
          </div>
          {isManualCrocPool && (
            <div>
              <Flex flex="1" flexDirection="column" alignSelf="flex-start">
                <>
                  {hasEarnings ? (
                    <>
                      <Balance
                        lineHeight="1"
                        bold
                        fontSize="20px"
                        decimals={2}
                        value={xEarningTokenBalance}
                        textAlign="right"
                      />
                      {earningTokenPrice > 0 && (
                        <Balance
                          display="inline"
                          fontSize="12px"
                          color="textSubtle"
                          decimals={2}
                          prefix="~"
                          value={xEarningTokenDollarBalance}
                          unit=" USD"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <Heading pt="0" color="textDisabled" textAlign="right">
                        0
                      </Heading>
                      <Text fontSize="12px" color="textDisabled">
                        0 USD
                      </Text>
                    </>
                  )}
                </>
              </Flex>
            </div>
          )}
        </ActionContent>
      </ActionBody>
      <Button onClick={onPresentCollect}>{isCompoundPool ? t('Collect') : t('Harvest')}</Button>
      {/* <Button disabled={!hasEarnings} onClick={onPresentCollect}>
        {isCompoundPool ? t('Collect') : t('Harvest')}
      </Button> */}
    </ActionContainer>
  )
}

export default HarvestAction
