import { Collections, CollectionKey } from './types'

const collections: Collections = {
  [CollectionKey.PANMMF]: {
    name: 'MMF Bunnies',
    slug: 'mmf-bunnies',
    address: {
      421611: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
      42161: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    },
  },
  [CollectionKey.SQUAD]: {
    name: 'MMF Squad',
    description: "MM.Finance's first official generative NFT collection.. Join the squad.",
    slug: 'mmf-squad',
    address: {
      421611: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
      42161: '0xEf12ef570300bFA65c4F022deAaA3dfF4f5d5c91',
    },
  },
}

export default collections
