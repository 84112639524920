import { ChainId, Token } from '@crocswap/mmf-arb-sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  mmf: new Token(
    MAINNET,
    '0x56b251d4b493ee3956E3f899D36b7290902D2326',
    18,
    'MMF',
    'Mad Meerkat Finance Token',
    'https://arbimm.finance/',
  ),
  xmmf: new Token(
    MAINNET,
    '0xB8635f1644422e7EbcA07C06b839075A74f57dBB',
    18,
    'xMMF',
    'X Mad Meerkat Finance Token',
    'https://arbimm.finance/',
  ),
  vemmf: new Token(
    MAINNET,
    '0x9A92b930A59a5361CEcd8965F8533aA3008a1477',
    18,
    'veMMF',
    'Vote Escrowed Mad Meerkat Finance Token',
    'https://arbimm.finance/',
  ),
  arb: new Token(MAINNET, '0x912CE59144191C1204E64559FE8253a0e49E6548', 18, 'ARB', 'Arbitrum', 'https://arbitrum.io/'),
  eth: new Token(MAINNET, '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 18, 'ETH', 'Ether', 'https://ethereum.org/en/'),
  weth: new Token(
    MAINNET,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped Ether',
    'https://ethereum.org/en/',
  ),

  // eth: new Token(
  //   MAINNET,
  //   '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
  //   18,
  //   'ETH',
  //   'Wrapped Ether',
  //   'https://ethereum.org/en/',
  // ),
  usdc: new Token(
    MAINNET,
    '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    6,
    'USDC',
    'USD Coin',
    'https://www.circle.com/en/usdc',
  ),
  wbtc: new Token(
    MAINNET,
    '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    8,
    'WBTC',
    'Wrapped BTC',
    'https://bitcoin.org/en/',
  ),
  usdt: new Token(MAINNET, '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', 6, 'USDT', 'Tether USD', 'https://tether.to/'),
  // mimas: new Token(
  //   MAINNET,
  //   '0x10C9284E6094b71D3CE4E38B8bFfc668199da677',
  //   18,
  //   'MIMAS',
  //   'Mimas finance',
  //   'https://mimas.finance/',
  // ),
  dai: new Token(
    MAINNET,
    '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    18,
    'DAI',
    'Dai Stablecoin',
    'https://makerdao.com/',
  ),
  gmc: new Token(
    MAINNET,
    '0x654C908305021b2eaF881cEe774ECe1D2BCac5fc',
    18,
    'GMC',
    'GMC Token',
    'https://www.gmcash.finance/',
  ),
  gshare: new Token(
    MAINNET,
    '0x0f96d8c1277BD75A251238af952A7A99Db1320E3',
    18,
    'GSHARE',
    'GSHARE Token',
    'https://www.gmcash.finance/',
  ),
  gmx: new Token(
    MAINNET,
    '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
    18,
    'GMX',
    'GMX Token',
    'https://www.gmcash.finance/',
  ),
  // liq: new Token(
  //   MAINNET,
  //   '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
  //   18,
  //   'LIQ',
  //   'Liquidus',
  //   'https://liquidus.finance/',
  // ),
  // dark: new Token(
  //   MAINNET,
  //   '0x83b2AC8642aE46FC2823Bc959fFEB3c1742c48B5',
  //   18,
  //   'DARK',
  //   'DARKCRYPTO SHARE',
  //   'https://www.darkcrypto.finance/',
  // ),
  // sky: new Token(
  //   MAINNET,
  //   '0x9D3BBb0e988D9Fb2d55d07Fe471Be2266AD9c81c',
  //   18,
  //   'SKY',
  //   'DARKCRYPTO SHARE',
  //   'https://www.darkcrypto.finance/',
  // ),
  // croissant: new Token(
  //   MAINNET,
  //   '0xa0C3c184493f2Fae7d2f2Bd83F195a1c300FA353',
  //   18,
  //   'ETHISSANT',
  //   'Croissant games',
  //   'https://croissant.games/',
  // ),
  // mino: new Token(
  //   MAINNET,
  //   '0x3A1138075bd97a33F23A87824b811146FA44288E',
  //   9,
  //   'MINO',
  //   'Minotaur money',
  //   'https://minotaur.money/',
  // ),
  // wsmino: new Token(
  //   MAINNET,
  //   '0x1066c6753FFaf8540F691643A6D683e23599c4ab',
  //   18,
  //   'wsMINO',
  //   'Minotaur money',
  //   'https://minotaur.money/',
  // ),
  // bison: new Token(
  //   MAINNET,
  //   '0x3405A1bd46B85c5C029483FbECf2F3E611026e45',
  //   18,
  //   'BISON',
  //   'BISON games',
  //   'https://bishares.finance/',
  // ),
  // gaur: new Token(MAINNET, '0x046cb616d7a52173e4Da9efF1BFd590550aa3228', 18, 'GAUR', 'GAUR', 'https://gaur.money/'),
  // gshares: new Token(
  //   MAINNET,
  //   '0x66ec6E9F61ac288f5BA661CD9a2dBe3aBf9871C9',
  //   18,
  //   'GSHARES',
  //   'GSHARES',
  //   'https://gaur.money/',
  // ),
  // crk: new Token(MAINNET, '0x065DE42E28E42d90c2052a1B49e7f83806Af0e1F', 9, 'CRK', 'CroKing', 'https://croking.net'),
  // crp: new Token(
  //   MAINNET,
  //   '0x7b8aD6d7560FAcd1959cfb4b4163D7d297c4bFc0',
  //   18,
  //   'CRP',
  //   'ETH Predict',
  //   'https://cropredict.finance/#',
  // ),
  // dna: new Token(
  //   MAINNET,
  //   '0xCc57F84637B441127f2f74905b9d99821b47b20c',
  //   18,
  //   'DNA',
  //   'DNA Dollar',
  //   'https://dnadollar.com/#',
  // ),
  // rna: new Token(
  //   MAINNET,
  //   '0xDe9E2ADDFd3BBadB67553CDb120c6C6593b180F9',
  //   18,
  //   'RNA',
  //   'RNA Dollar',
  //   'https://dnadollar.com/#',
  // ),
  // moon: new Token(MAINNET, '0x7D30c36f845d1dEe79f852abF3A8A402fAdF3b53', 9, 'MOON', 'CroMoon', 'https://cromoon.net/'),
  // ann: new Token(MAINNET, '0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067', 18, 'ANN', 'Annex', 'https://annex.finance/'),
  // svn: new Token(MAINNET, '0x654bAc3eC77d6dB497892478f854cF6e8245DcA9', 18, 'SVN', 'Savanna', 'https://svn.finance/'),
  // sphere: new Token(
  //   MAINNET,
  //   '0xc9FDE867a14376829Ab759F4C4871F67e2d3E441',
  //   18,
  //   'SPHERE',
  //   'SPHERE Token',
  //   'https://www.polygonphere.org/',
  // ),
  // bacc: new Token(
  //   MAINNET,
  //   '0xa57a7B5F8067156C2DbB06cf5e4d8aCEF17aeE64',
  //   18,
  //   'BACC',
  //   'Bored',
  //   'https://www.boredapepolygonclub.com',
  // ),
  // mshare: new Token(
  //   MAINNET,
  //   '0xf8b9facB7B4410F5703Eb29093302f2933D6E1Aa',
  //   18,
  //   'MSHARE',
  //   'MSHARE Token',
  //   'https://svn.finance/',
  // ),
  // betify: new Token(
  //   MAINNET,
  //   '0xD465b6B4937D768075414D413e981Af0b49349Cc',
  //   9,
  //   'BETIFY',
  //   'BETIFY Token',
  //   'https://gamblefi.io/',
  // ),
  // cgs: new Token(
  //   MAINNET,
  //   '0x4e57e27e4166275Eb7f4966b42A201d76e481B03',
  //   18,
  //   'CGS',
  //   'Cougar Token',
  //   'https://polygonapp.cougarswap.io/',
  // ),
  // single: new Token(
  //   MAINNET,
  //   '0x0804702a4E749d39A35FDe73d1DF0B1f1D6b8347',
  //   18,
  //   'SINGLE',
  //   'Single Token',
  //   'https://singlefinance.io/',
  // ),
  // ago: new Token(
  //   MAINNET,
  //   '0x383627CaeC2CE3b36793c34B576B2e97BEDA0466',
  //   18,
  //   'AGO',
  //   'Agora Token',
  //   'https://agoracro.com/',
  // ),
  // bushi: new Token(
  //   MAINNET,
  //   '0xe7e479FCC3A722225fdBfA8Faea556E8a5eD904a',
  //   18,
  //   'BUSHI',
  //   'Bushicoins Token',
  //   'https://bushicro.io/',
  // ),
  // grve: new Token(
  //   MAINNET,
  //   '0x9885488cD6864DF90eeBa6C5d07B35f08CEb05e9',
  //   18,
  //   'GRVE',
  //   'Grave token',
  //   'https://croskull.com/',
  // ),
  // metf: new Token(
  //   MAINNET,
  //   '0xB8Df27c687c6af9aFE845A2aFAD2D01e199f4878',
  //   18,
  //   'METF',
  //   'METF token',
  //   'https://metf.finance/',
  // ),
  // goal: new Token(
  //   MAINNET,
  //   '0x00fe915a5209e74D5a88334cC2daA4541AEC8278',
  //   18,
  //   'GOAL',
  //   'GOAL token',
  //   'https://polygonfc.club/',
  // ),
  // srv: new Token(MAINNET, '0xfad0C1e9d37A20c32e07599B39e6279687273036', 9, 'SRV', 'SRV token', 'https://srv.finance/'),
  // croge: new Token(
  //   MAINNET,
  //   '0xC4a174cCb5fb54a6721e11e0Ca961e42715023F9',
  //   9,
  //   'ETHGE',
  //   'ETHGE token',
  //   'https://www.crogecoin.com/',
  // ),
  // mad: new Token(
  //   MAINNET,
  //   '0x212331e1435A8df230715dB4C02B2a3A0abF8c61',
  //   18,
  //   'MAD',
  //   'MAD bucks token',
  //   'https://madmeerkat.io/',
  // ),
  // pes: new Token(
  //   MAINNET,
  //   '0x8EfBaA6080412D7832025b03B9239D0be1e2aa3B',
  //   18,
  //   'PES',
  //   'Pegasus Dollar token',
  //   'https://pegasusdollar.finance/',
  // ),
  // phnx: new Token(
  //   MAINNET,
  //   '0x57d06bB1e3B60C875cD3A4445a53217F9B44d390',
  //   18,
  //   'PHNX',
  //   'Phenix Finance',
  //   'https://phenix.finance/',
  // ),
  // lion: new Token(
  //   MAINNET,
  //   '0x49fB98F9b4a3183Cd88e7a115144fdf00fa6fB95',
  //   18,
  //   'LION',
  //   'LION token',
  //   'https://scrub.finance/',
  // ),
  // tiger: new Token(
  //   MAINNET,
  //   '0xD6597AA36DD90d7fCcBd7B8A228F2d5CdC88eAd0',
  //   18,
  //   'TIGER',
  //   'TIGER token',
  //   'https://scrub.finance/',
  // ),
  // croki: new Token(
  //   MAINNET,
  //   '0x43e90012a3d69AeBdc2750424b41655776c6D598',
  //   9,
  //   'ETHKI',
  //   'ETHKI token',
  //   'https://crokicoin.com',
  // ),
  // musd: new Token(
  //   MAINNET,
  //   '0x95aEaF383E2e86A47c11CffdE1F7944eCB2C38C2',
  //   18,
  //   'MUSD',
  //   'Mad USD token',
  //   'https://mmf.money',
  // ),
  // burrow: new Token(
  //   MAINNET,
  //   '0x49ab7ca4D2Cf6777c8C56C5E9Edb7D1218AE1299',
  //   18,
  //   'BURROW',
  //   'MMF Money',
  //   'https://mmf.money',
  // ),
  // hkn: new Token(
  //   MAINNET,
  //   '0xa60943a1B19395C999ce6c21527b6B278F3f2046',
  //   18,
  //   'HKN',
  //   'HAKUNA Token',
  //   'https://hkn.finance',
  // ),
  // cron: new Token(
  //   MAINNET,
  //   '0xD8eB4845B094e05f4d01d7BB3468238076F744f1',
  //   18,
  //   'ETHN',
  //   'PolygonNode Token',
  //   'https://polygonnode.com/',
  // ),
  // deer: new Token(
  //   MAINNET,
  //   '0x6D6D6ba0c7e7DBaFffeC82b1ddF92e271650a63A',
  //   18,
  //   'DEER',
  //   'ToxicDeer Finance',
  //   'https://toxicdeer.finance/',
  // ),
  // xdshare: new Token(
  //   MAINNET,
  //   '0x6F715158d4B1468528DA002F5941c72fe4159520',
  //   18,
  //   'XDSHARE',
  //   'ToxicDeer Shares',
  //   'https://toxicdeer.finance/',
  // ),
  // donut: new Token(
  //   MAINNET,
  //   '0x2258459C937D0b4Cb58289718c900b43c9C4813a',
  //   18,
  //   'DONUT',
  //   'DONUT Token',
  //   'https://donut.financial/',
  // ),
  // mng: new Token(
  //   MAINNET,
  //   '0xC9b23289c60783649AA327732FCCEc2f5d0aC466',
  //   18,
  //   'MNG',
  //   'Mongoose Token',
  //   'https://saharadao.finance',
  // ),
  // dinop: new Token(
  //   MAINNET,
  //   '0x7091002B330D8054cb8584e5057451Ba983b975E',
  //   18,
  //   'DINOP',
  //   'DINOP Token',
  //   'https://dinopool.finance/',
  // ),
  // sdinop: new Token(
  //   MAINNET,
  //   '0xC21718b8a93529d33E7b5dCdFF439402c47428aC',
  //   18,
  //   'sDINOP',
  //   'sDINOP Token',
  //   'https://dinopool.finance/',
  // ),
  // mmfx: new Token(
  //   MAINNET,
  //   '0xC9b23289c60783649AA327732FCCEc2f5d0aC466',
  //   18,
  //   'MMFX',
  //   'MMFX Token',
  //   'https://saharadao.finance',
  // ),
  // xargo: new Token(
  //   MAINNET,
  //   '0xb966B5D6A0fCd5b373B180Bbe072BBFbbEe10552',
  //   18,
  //   'xARGO',
  //   'xARGO Token',
  //   'https://www.argofinance.money',
  // ),
  // bcro: new Token(
  //   MAINNET,
  //   '0xeBAceB7F193955b946cC5dd8f8724a80671a1F2F',
  //   18,
  //   'bETH',
  //   'Bonded ETH',
  //   'https://www.argofinance.money',
  // ),
  // mmusd: new Token(
  //   MAINNET,
  //   '0xbe2E9cE90F019db61419807D690d6F48E984ab8F',
  //   18,
  //   'MMUSD',
  //   'Sahara MUSD pegged Token',
  //   'https://saharadao.finance/',
  // ),
  // '10mb': new Token(
  //   MAINNET,
  //   '0x02A8Dc66334B1cc6CD8F28Fe8DBF6b58B49b47B6',
  //   18,
  //   '10MB',
  //   '10MB Token',
  //   'https://10mb.finance/',
  // ),
  // '10share': new Token(
  //   MAINNET,
  //   '0xD8d40DcEE0C2B486EEBd1FEDb3F507B011De7Ff0',
  //   18,
  //   '10SHARE',
  //   '10SHARE Token',
  //   'https://10mb.finance/',
  // ),
  // 'mmox': new Token(
  //   MAINNET,
  //   '0x3044d651b5A8F92A261Afe11D4b4E4CB29DdA1D0',
  //   18,
  //   'MMOX',
  //   'MMOX Token',
  //   'https://saharadao.finance/',
  // ),
  // 'shift': new Token(
  //   MAINNET,
  //   '0x4F9BD6BE8455EE2b3c7FF76BbB885e6654797137',
  //   18,
  //   'SHIFT',
  //   'SHIFT Token',
  //   'https://www.shiftdollar.finance/',
  // ),
  // 'sfshare': new Token(
  //   MAINNET,
  //   '0xE64fF204Df5f3D03447dA4895C6DA1fB590F1290',
  //   18,
  //   'SFSHARE',
  //   'SFSHARE Token',
  //   'https://www.shiftdollar.finance/',
  // )
}

// FIXME tokens for testnet and mainnet
export const testnetTokens = {
  mmf: new Token(
    TESTNET,
    '0x97749c9B61F878a880DfE312d2594AE07AEd7656',
    18,
    'MMF',
    'Mad Meerkat Finance Token',
    'https://arbimm.finance/',
  ),
  matic: new Token(TESTNET, '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 18, 'ETH', 'ETH', 'https://crypto.org/'),
  wmatic: new Token(
    TESTNET,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped ETH',
    'https://crypto.org/',
  ),
  eth: new Token(
    TESTNET,
    '0x441d72d584b16105FF1C68DC8bc4517F4DC13E55',
    18,
    'ETH',
    'Wrapped Ether',
    'https://ethereum.org/en/',
  ),
  weth: new Token(
    TESTNET,
    '0x441d72d584b16105FF1C68DC8bc4517F4DC13E55',
    18,
    'WETH',
    'Wrapped Ether',
    'https://ethereum.org/en/',
  ),
  usdc: new Token(
    TESTNET,
    '0x321106E51b78E0E9CEBcFeC63C5250F0F3CcB82b',
    6,
    'USDC',
    'USD Coin',
    'https://www.circle.com/en/usdc',
  ),
  wbtc: new Token(
    TESTNET,
    '0xFFc8ce84a196420d7bCCDEe980c65364eD1f389F',
    8,
    'WBTC',
    'Wrapped BTC',
    'https://bitcoin.org/en/',
  ),
  mmo: new Token(
    TESTNET,
    '0x50c0C5bda591bc7e89A342A3eD672FB59b3C46a7',
    8,
    'MMO',
    'MM Optimiser',
    'https://vaults.arbimm.finance/vault',
  ),
}

const tokens = (): TokenList => {
  // const chainId = process.env.REACT_APP_CHAIN_ID

  // // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  // if (parseInt(chainId, 10) === ChainId.TESTNET) {
  //   // return Object.keys(mainnetTokens).reduce((accum, key) => {
  //   //   return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
  //   // }, {})
  //   return testnetTokens // FIXME avoid this mainnet override testnet hack
  // }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
