export const MINIMUM_SEARCH_CHARACTERS = 2

export const WEEKS_IN_YEAR = 52.1429

export const TOTAL_FEE = 0.0017
export const LP_HOLDERS_FEE = 0.001
export const TREASURY_FEE = 0.0005
export const BUYBACK_FEE = 0.0002

export const PCS_V2_START = 72404739 // December-10-2021 07:58:21 PM +8 UTC
export const ONE_DAY_UNIX = 86400 // 24h * 60m * 60s
export const ONE_HOUR_SECONDS = 3600

export const ITEMS_PER_INFO_TABLE_PAGE = 10

// These tokens are either incorrectly priced or have some other issues that spoil the query data
// None of them present any interest as they have almost 0 daily trade volume
export const TOKEN_BLACKLIST = [
  '0x08fd6f8d38b00eb024c2c0c3abe56841eed47619',
  '0xadbd1231fb360047525bedf962581f3eee7b49fe', // crona
  '0xd9f9cbfd6040f2b72153f130c9d4a0a72b859905', // fake test token
  '0xed52eeaeedb59d8623200d18c1ddf1642cb1b915', // cro test
  '0x08fd6f8d38b00eb024c2c0c3abe56841eed47619', // tesla
  '0x2d03bece6747adc00e1a131bba1469c15fd11e03',
  '0x583e9551A570Ec36916dbbEB826cACc6C1B5e65D',
  '0x50c0C5bda591bc7e89A342A3eD672FB59b3C46a7',
  '0xf23322b390ce57c10c27ec40bb3994e47404c99e',
  '0x99002ad0bb0cf2a24cdd1ea6a2ceead775b86923', // merkel
  '0x99002Ad0bb0cf2A24Cdd1EA6a2ceeaD775b86923',
  '0xd76dd15ec3f6c48e2aa4cb303c2751f02f82b787', // bl4k
  '0xD76Dd15EC3f6C48E2aa4cB303C2751f02F82B787',
  '0xCD6ec1ca885b92d5C7Ee58Dfa6e746c60274AcB1', // cro bargain
  '0xa83349da382ec7a002f40ea4e43315eedf020755',
]

export const USER_BLACKLIST = [
  // "0x307281f298eab47894751645473e09214ad20cdf",
  // "0x0f02da65578cab976152e33b1792692c388de543",
  // "0xee05f81328ab7eb866607f34e324ce7b67398bf3 ",
  // "0xeca2ce0a52a41c7d84c6d4cecad6af951588c5bb ",
  // "0xcc4cd8549748d7837ae83bdd4b8fcf833b666a45 ",
  // "0xb26ef0b531c1b19b1554a638a043490a3638167d ",
  // "0xf13f7bf69a5e57ea3367222c65dd3380096d3fbf ",
  // "0x67151a1ea1C1547B760a390103934aF0A39b7a3b",
  // "0x101785025A90ae6a865E3161FF37a7483a088ADf",
  // "0x0c1a3E4E1C3DA4c89582dfA1AFA87A1853D7f78f",
  // "0x160dcdf4d3f31a730648cc94fc8d656131d96c0b",
  // "0x8abab939cc1e72c4147642111b5778cfeabf5403",
]
